export const closeOnEscape = (closeFunc, elementToReceiveFocus) => {
    const listener = e => {
        if (e.keyCode === 27) {
            closeFunc();
            if (elementToReceiveFocus) {
                elementToReceiveFocus.focus();
            }
            window.removeEventListener('keydown', listener);
            window.removeEventListener('keydown', window.tabTrapper);
        }
    };
    window.addEventListener('keydown', listener);
};

export const tabTrapper = container => {
    const tabables = container.querySelectorAll(
        'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    const firstIsRadio = tabables && tabables[0].type === 'radio';
    const lastIsRadio = tabables && tabables[tabables.length - 1].type === 'radio';

    const radioGroups = {};
    tabables.forEach(e => {
        if (e.type === 'radio') {
            if (!radioGroups[e.name]) {
                radioGroups[e.name] = [];
            }
            radioGroups[e.name].push(e);
        }
    });

    const lastElement = list => Object.keys(list)[Object.keys(list).length - 1];

    const handleFocusRadioButton = () => {
        const activeElementName = document.activeElement.name;
        const radioGroupContainsActiveElement = radioGroups[activeElementName] && true;
        if (document.activeElement.type === 'radio' && radioGroupContainsActiveElement) {
            const checked = radioGroups[activeElementName].find(e => e.checked) || radioGroups[activeElementName][0];
            checked.focus();
        }
    };

    const tabBack = (active, e) => {
        if ((active.name === Object.keys(radioGroups)[0] && firstIsRadio) || active === tabables[0]) {
            e.preventDefault();
            tabables[tabables.length - 1].focus();
        }
    };

    const tabForward = (active, e) => {
        if ((active.name === lastElement(radioGroups) && lastIsRadio) || active === tabables[tabables.length - 1]) {
            e.preventDefault();
            tabables[0].focus();
        }
    };

    window.tabTrapper = e => {
        const activeElement = document.activeElement;

        if (e.shiftKey && e.keyCode === 9) {
            tabBack(activeElement, e);
        } else if (!e.shiftKey && e.keyCode === 9) {
            tabForward(activeElement, e);
        }
        handleFocusRadioButton();
    };

    if (!Object.keys(container.dataset).includes('noInitialFocus')) {
        tabables[0].focus();
    }
    handleFocusRadioButton();
    window.addEventListener('keydown', window.tabTrapper);
};

export const removeItemFromList = (listItem, list, duration = 300) => {
    const item = listItem;
    const height = item.scrollHeight;
    item.style.height = `${height}px`;
    item.style.transition = `${duration}ms`;

    requestAnimationFrame(() => {
        item.style.height = `${0}px`;
        item.style.padding = 0;
        item.style.borderWidth = 0;
        item.style.transform = 'scale(0)';
    });
    setTimeout(() => list.removeChild(item), duration);
};

export const generateUuid = () =>
    window.crypto && window.crypto.randomUUID ? crypto.randomUUID() : Math.random()
            .toString(36)
            .substring(2, 15) +
        Math.random()
            .toString(36)
            .substring(2, 15) +
        Date.now().toString();

// IE 11 doesn't support #Element.closest()
export const closestAncestorWithTag = (node, tag) => {
    if (!node.parentNode) {
        return null;
    }

    return node.parentNode.tagName === tag.toUpperCase()
        ? node.parentNode
        : closestAncestorWithTag(node.parentNode, tag);
};

export function capitalize(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function wait(millisecondsToWait) {
    return new Promise(resolve => setTimeout(resolve, millisecondsToWait));
}
